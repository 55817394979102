
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import CodeConfirmation from '@/components/pages/auth/CodeConfirmation.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmCountdown from '@/components/shared/TmCountdown.vue'

export default defineComponent({
  components: {
    PageAuth,
    TmButton,
    CodeConfirmation,
    TmCountdown,
  },
  setup() {
    const confirmation = ref({
      code: '',
    })

    return {
      confirmation,
    }
  },
})
